/** @format */

import React from "react";
import { UserCard } from "react-ui-cards";
import "./styles/ContactCards.css";

const ContactCards = ({ language }) => {
  const getHeader = (defaultHeader, translatedHeader) => {
    return language === "en" ? defaultHeader : translatedHeader;
  };

  return (
    <div class="cards" style={{ display: "flex" }}>
      <UserCard
        float
        href="mailto:petr@petrfort.cz"
        header={getHeader(
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:32/v1678852046/producer_lc8z1a.webp",
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:50/v1677453540/producent_xjymrm.webp"
        )} // Replace "Translated_Header_URL_1" with the actual translated URL.
        avatar="https://res.cloudinary.com/ddw1u49ec/image/upload/ar_1:1,b_rgb:262c35,bo_5px_solid_rgb:ff0000,c_crop,g_faces,r_max,w_477,x_0,y_0/v1677452974/DSC02297-2048x1365_uaruia.webp"
        name="Petr Fořt"
        positionName="www.petrfort.cz"
      />
      <UserCard
        float
        href="mailto:katka@dorianfilm.cz"
        header={getHeader(
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:32/v1678852046/accountant_k84hmd.webp",
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:50/v1677453540/ekonomka_qlzbul.webp"
        )} // Replace "Translated_Header_URL_2" with the actual translated URL.
        avatar="https://res.cloudinary.com/ddw1u49ec/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1677452995/accounting_dahkxd.webp"
        name="Kateřina Račková"
        positionName="katka@dorianfilm.cz&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;+420 733 547 563"
      />
      <UserCard
        float
        href="mailto:produkce@dorianfilm.cz"
        header={getHeader(
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:32/v1678852046/produkce_k8tgpf_cwlgpq.webp",
          "https://res.cloudinary.com/ddw1u49ec/image/upload/e_sharpen:88/v1677453540/produkce_k8tgpf.webp"
        )}
        avatar="https://res.cloudinary.com/ddw1u49ec/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1677453034/Top-10-movie-industries_zfqlg4.webp"
        name="Produkce"
        positionName="produkce@dorianfilm.cz&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;DORIAN film s.r.o.&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;Karmelitská 382/14&#8195;118 00 Praha 1"
      />
    </div>
  );
};

export default ContactCards;
