/** @format */

import "./App.css";
import { useState, useEffect, useRef } from "react";
import { SocialIcon } from "react-social-icons";
import { ContactUs } from "./components/ContactUs";
import ContactCards from "./components/ContactCards";
import LandingPage from "./components/LandingPage";
import { gsap } from "gsap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function App() {
  const headerRef = useRef();
  const headerImageRef = useRef();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [isEntered, setIsEntered] = useState(false);
  const appRef = useRef(null);
  const [language, setLanguage] = useState("cs");

  useEffect(() => {
    if (isEntered) {
      gsap.to(appRef.current, { scale: 1, duration: 1 });
      gsap.to(appRef.current, { opacity: 1, duration: 2 });
    }
  }, [isEntered]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = headerRef.current.offsetHeight;
      const headerImageHeight = headerImageRef.current.offsetHeight * 1.1;
      const opacity =
        1 -
        Math.max(0, Math.min(scrollPosition, headerImageHeight)) /
          headerImageHeight;
      headerImageRef.current.style.opacity = opacity;

      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        if (
          scrollPosition >= section.offsetTop - headerHeight &&
          scrollPosition <
            section.offsetTop + section.offsetHeight - headerHeight
        ) {
          const id = section.getAttribute("id");
          setSelectedMenuItem(id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const handleEnter = () => {
    setIsEntered(true);
  };

  if (!isEntered) {
    return (
      <LandingPage
        onEnter={handleEnter}
        onLanguageChange={handleLanguageChange}
      />
    );
  }

  return (
    <div className="App" ref={appRef} style={{ opacity: 0, scale: 0 }}>
      <header className="App-header" ref={headerRef}>
        <nav className="nav-menu">
          <ul>
            <li>
              <a
                href="#new"
                onClick={() => handleScrollToSection("new")}
                className={selectedMenuItem === "new" ? "active" : ""}
              >
                {language === "en" ? "New" : "Aktuálně"}
              </a>
            </li>
            <li>
              <a
                href="#projects"
                onClick={() => handleScrollToSection("projects")}
                className={selectedMenuItem === "projects" ? "active" : ""}
              >
                {language === "en" ? "Projects" : "Projekty"}
              </a>
            </li>
            <li>
              <a
                href="#contacts"
                onClick={() => handleScrollToSection("contacts")}
                className={selectedMenuItem === "contacts" ? "active" : ""}
              >
                {language === "en" ? "Contacts" : "Kontakty"}
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="header-container">
        <img
          loading="lazy"
          className="header-image"
          src="https://res.cloudinary.com/ddw1u49ec/image/upload/e_auto_contrast/v1677450534/header_anfwo0.webp"
          alt="Header"
          ref={headerImageRef}
        />
      </div>
      <section id="new">
      <h1>{language === "en" ? "News" : "Aktuálně"}</h1>
      <div className="full-width-image-container">
    <img src="https://res.cloudinary.com/ddw1u49ec/image/upload/v1704533313/rikajimilars_xnlwtn.jpg" alt="Full Width" />
</div>
      </section>
      <section className="image-description-section">
    <h2 className="sub-header">Pracujeme na: Říkají mi Lars</h2>
    <p>
    Příběh je poctou americké drsné škole odehrávající se v současné Praze, hlavní hrdina Lars má holou hlavu, fousy, tetování pod křivákem, jezdí na motorce a řeší problémy zadavatelů nebo je pro ně vytváří.
    </p>
    <p>
    Obstarání kompromitujícího videa manželky realitního magnáta se vyvine v únos milenky a vyvrcholí závěrečnou scénou, kde se odkryjí karty a odhalí identita a morálka všech zúčastněných. Čtyři muži, podvedeni ženou, zůstávají spoutaní v opuštěné továrně. Komu z nich se podaří uvolnit pouta jako prvnímu..?
    </p>
</section>
      <div className="projects">
    <section id="projects">
        <Carousel 
            showArrows={true} 
            infiniteLoop={true} 
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={3000}>
            <div>
                <img src="https://res.cloudinary.com/ddw1u49ec/image/upload/v1704534474/buko-plakat-wide_rhtmfl.jpg" alt="Buko" />
                <p className="legend">Buko</p>
            </div>
            <div>
                <img src="https://res.cloudinary.com/ddw1u49ec/image/upload/v1704534014/plaga%CC%81t_TKP-wide_ntzgus.jpg" alt="Třetí konec palice" />
                <p className="legend">Třetí konec palice</p>
            </div>
            {/* Add more slides here if needed */}
        </Carousel>
    </section>
</div>

      <section id="contacts">
        <h2>{language === "en" ? "Contacts" : "Kontakty"}</h2>
        <ContactCards language={language} />
        <ContactUs language={language} />
      </section>
      <section id="about">
        <p>
          {language === "en"
            ? "DORIAN film is a young, independent production company based in Prague. We focus on feature-length Czech feature films, creating a pleasant environment for creators and delighting audiences in cinemas. And yet we are bold in choosing topics and creators."
            : "DORIAN film je mladá, nezávislá produkční společnost sídlící v Praze. Chceme se zaměřovat na celovečerní české hrané filmy, vytvářet tvůrcům příjemné zázemí a potěšit diváky v kinech. A přitom být odvážní ve výběru témat i tvůrců."}
        </p>
      </section>
      <footer className="footer-container">
        <div className="facebook">
          <SocialIcon
            url="https://www.facebook.com/BUKO.FILM"
            target="_blank"
            bgColor="grey"
          />
        </div>
        <div className="instagram">
          <SocialIcon
            url="https://www.instagram.com/filmbuko/"
            target="_blank"
            bgColor="grey"
          />
        </div>
        <div className="twitter">
          <SocialIcon
            url="https://twitter.com/FortTadeas"
            target="_blank"
            bgColor="grey"
          />
        </div>
        <div className="linkedin">
          <SocialIcon
            url="https://www.linkedin.com/company/dorian-film/"
            target="_blank"
            bgColor="grey"
          />
        </div>
      </footer>
    </div>
  );
}
export default App;
