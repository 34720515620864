/** @format */

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./styles/ContactUs.css";

export const ContactUs = ({ language }) => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_5g9r3sc",
        "template_c6a8lkc",
        form.current,
        "LOnZ4dDWASNQAyvl6"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="contact-us">
      <h1>{language === "en" ? "Write to us" : "Napište nám"}</h1>
      {isSubmitted ? (
        <p className="success">
          {language === "en" ? "Message sent!" : "Zpráva odeslána!"}
        </p>
      ) : (
        <form
          ref={form}
          onSubmit={sendEmail}
          className={isSubmitting ? "submitting" : ""}
        >
          <label>{language === "en" ? "Name" : "Jméno"}</label>
          <input type="text" name="from_name" />
          <label>{language === "en" ? "Your email" : "Váš e-mail"}</label>
          <input type="email" name="from_email" />
          <label>{language === "en" ? "Message" : "Zpráva"}</label>
          <textarea name="message" />
          <input
            type="submit"
            value={language === "en" ? "Send" : "Odeslat"}
            disabled={isSubmitting}
          />
          {isSubmitting && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </form>
      )}
    </div>
  );
};
