/** @format */

import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./styles/LandingPage.css";
import cena from "./images/cena.png";

const LandingPage = ({ onEnter, onLanguageChange }) => {
  const [language, setLanguage] = useState("cs");
  const titleRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    gsap.to([titleRef.current], {
      opacity: 1,
      duration: 0,
      onComplete: () => {
        gsap.to([titleRef.current], {
          opacity: 1,
          duration: 1,
        });
      },
    });
    gsap.to([buttonRef.current], {
      opacity: 1,
      duration: 0,
      onComplete: () => {
        gsap.to([buttonRef.current], {
          opacity: 1,
          duration: 1,
        });
      },
    });
  }, [language]);

  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "cs" : "en";
    setLanguage(newLanguage);
    onLanguageChange(newLanguage); // Pass language state to parent
  };

  return (
    <div className="landing-page">
      <img
        src={cena}
        alt="cena"
        className="landing-page__image"
        style={{ opacity: 0.5 }}
      />
      <h1 className="landing-page__title" ref={titleRef}>
        {language === "en" ? "Welcome" : "Vítejte"}
      </h1>
      <button
        className="landing-page__button"
        onClick={onEnter}
        ref={buttonRef}
      >
        {language === "en" ? "Enter" : "Vstoupit"}
      </button>
      <div className="landing-page__language-switch">
        <label className="landing-page__switch">
          <input
            type="checkbox"
            checked={language === "cs"}
            onChange={handleLanguageSwitch}
          />
          <span className="landing-page__slider"></span>
          <span className="landing-page__language">
            {language === "en" ? "English" : "Čeština"}
          </span>
        </label>
      </div>
    </div>
  );
};

export default LandingPage;
